<template>
  <div class="commonSelection">
    <div class="selected">
      <div class="title">{{ $t("be315ca") }}：</div>
      <div class="content">
        <div v-if="showClear" class="clear item" @click="clearSelect">
          {{ $t("df61523") }}
        </div>
        <div v-for="(content, key) in selectObj" :key="key" class="item">
          {{ content.name }}
          <div class="closeSel" @click="clearItem(content, key)"></div>
        </div>
      </div>
    </div>
    <div class="conditionSelection">
      <div
        v-for="(item, index) of options"
        v-if="!item.colse"
        :key="index"
        class="condition"
      >
        <div class="title">{{ $t(item.name) }}：</div>
        <div class="content">
          <div
            v-for="(content, contentIndex) of item.list"
            :key="content.id"
            class="item"
            :class="{
              active: selectObj[item.key]
                ? selectObj[item.key].name === content.name
                : false,
            }"
            @click="selectItem(contentIndex, content, item)"
          >
            <el-popover
              v-if="showPopover && content.child"
              placement="bottom-start"
              width="1050"
              trigger="hover"
              popper-class="popoverContainer"
            >
              <div class="childContainer">
                <div
                  v-for="(childItme, childIndex) of content.child.list"
                  :key="childItme.id + index"
                  class="childItem"
                  :class="{
                    active: selectObj[content.child.key]
                      ? selectObj[content.child.key].name === childItme.name
                      : false,
                  }"
                  @click="
                    selectItem(
                      childIndex,
                      childItme,
                      content.child,
                      content,
                      item
                    )
                  "
                >
                  {{ childItme.name }}
                </div>
              </div>
              <span slot="reference">{{ content.name }}</span>
            </el-popover>

            <span v-else>{{ content.name }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConditionSelection",
  props: {
    options: {
      type: Array,
      required: true,
    },
    searchInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activeIndex: 0,
      selectObj: {},
      countryList: {},
    };
  },
  computed: {
    showClear() {
      return Object.keys(this.selectObj).length;
    },
    showPopover() {
      const path = this.$route.path;
      return path === "/service/demand" || path === "/service/desk";
    },
  },
  watch: {
    options(newVal) {
      if (newVal) {
        this.selectObj = {};
      }
    },
  },
  created() {},
  methods: {
    // showPopover(child) {
    //   return child && (path === '/service/demand' || path === '/service/desk')
    // },

    // handleAllChildAdd(key) {
    //   this.handleAddParent(key, 'country', 'province')
    //   this.handleAddParent(key, 'type', 'businessTypeId')
    // },
    // handleAddParent(key, parentKey, childKey) {
    //   if (key === parentKey && this.selectObj[childKey]) {
    //     this.$delete(this.selectObj, childKey)
    //     this.searchInfo[childKey] = ''
    //   }
    // },
    handleAllChildReset(key) {
      this.handleResetChild(key, "country", "province");
      this.handleResetChild(key, "provinceId", "areaId");
      this.handleResetChild(key, "countryId", "provinceId");
      this.handleResetChild(key, "countryId", "areaId");
      this.handleResetChild(key, "type", "businessTypeId");
    },
    handleResetChild(key, parentKey, childKey) {
      if (key === parentKey && this.selectObj[childKey]) {
        this.$delete(this.selectObj, childKey);
        this.searchInfo[childKey] = "";
      }
    },
    clearItem(content, key) {
      this.handleAllChildReset(key);
      this.searchInfo[key] = "";
      this.$delete(this.selectObj, key);
      this.search();
    },
    clearSelect() {
      this.search("clear");
      this.selectObj = {};
    },
    selectItem(index, content, item, parentContent, parentItem) {
      const key = item.key;
      this.handleAllChildReset(key);
      if (parentContent) {
        this.$set(this.selectObj, parentItem.key, parentContent);
      }
      if (key === "provinceId") {
        this.searchInfo.provinceIndex = index;
      }
      this.$set(this.selectObj, key, content);
      this.search();
    },
    handleClear(key) {
      const obj = {
        countryId: 1,
        // provinceId: 370000000000,
        provinceIndex: 14,
      };
      return obj[key] || "";
    },
    search(type) {
      const array = Object.keys(this.selectObj);
      for (let index = 0; index < array.length; index++) {
        const key = array[index];
        const val = this.selectObj[key];
        let result = val ? val.id : "";
        if (type === "clear") result = this.handleClear(key);
        this.searchInfo[key] = result;
      }
      this.$emit("search");
    },
  },
};
</script>

<style scoped lang="less">
@import "~@/styles/variables.less";

.commonSelection {
  display: flex;
  flex-direction: column;
  width: @uni-width;
  margin-bottom: 40px;
  background: rgba(0, 164, 255, 0.04);
  border-radius: 4px;
  color: #333333;

  .title {
    display: flex;
    justify-content: flex-end;
    width: 70px;
  }
  .item {
    margin-left: 20px;
    cursor: pointer;
  }

  .selected {
    display: flex;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid #eeeeee;
    font-size: 13px;
    .content {
      display: flex;
      align-items: center;
      min-height: 25px;
      font-weight: bold;
      color: #42a5f5;
      .item {
        position: relative;
        padding: 5px 10px;
        border-radius: 2px;
        border: 1px solid #42a5f5;
        .closeSel {
          position: absolute;
          top: -6px;
          right: -6px;
          width: 12px;
          height: 12px;
          background: url("~@/assets/service/closeSel.png");
          background-size: 12px 12px;
          cursor: pointer;
        }
      }
      .clear {
        padding-left: 0;
        color: #cccccc;
        border: none;
        cursor: pointer;
        &::after {
          display: none;
        }
      }
    }
  }

  .conditionSelection {
    display: flex;
    flex-direction: column;
    // align-items: center;
    padding: 20px;
    font-size: 13px;
    .condition {
      display: flex;
      margin-bottom: 10px;
    }
    .title {
      white-space: nowrap;
      color: #333333;
    }
    .content {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      max-width: 90%;
      .item {
        margin-bottom: 10px;
        color: #666666;
      }
      .active {
        color: @uni-text-color-select;
      }
    }
  }
}
.popoverContainer {
  .childContainer {
    display: flex;
    flex-wrap: wrap;
    padding: 15px 0px 30px 20px;
    font-size: 14px;
    color: #999999;
    line-height: 19px;
    .childItem {
      margin: 20px 20px 0 0;
      cursor: pointer;
      &:hover {
        color: @uni-text-color-select;
      }
    }
    .active {
      color: @uni-text-color-select;
    }
  }
}
</style>
