<template>
  <div class="search">
    <div class="context">
      <input
        v-model="searchInfo.keyword"
        class="searchInput"
        type="text"
        :placeholder="$t(releaseInfo.pla)"
      />
      <span class="inputPrefix">
        <i class="el-input__icon el-icon-search"></i>
      </span>
    </div>
    <div class="searchBuuton" type="primary" @click="search">
      {{ $t('3a3636a') }}
    </div>
    <Publish :release-info-path="releaseInfo.path" :check-lawyer="checkLawyer">
      <div v-if="showReplace" class="release">
        {{ $t(releaseInfo.title) }}
        <!--        {{ searchInfo.type }}-->
      </div>
    </Publish>
  </div>
</template>

<script>
import { isServiceManage } from '@/utils/jurisdiction'
import Publish from '@/components/PermissionContainer/Publish'
export default {
  name: 'Search',
  components: {
    Publish,
  },
  props: {
    checkLawyer: {
      type: Boolean,
      default: true,
    },
    releaseInfo: {
      type: Object,
      required: true,
    },
    searchInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      searchInput: '',
      showReplace: true,
    }
  },
  computed: {

  },
  watch: {
    // 'releaseInfo.path': {
    //   immediate: true,
    //   handler(newValue) {
    //     if (newValue) {
    //       this.showServiceManage()
    //     }
    //   }
    // }
  },
  activated() {
    this.showServiceManage()
  },
  methods: {
    showServiceManage() {
      let result = true
      if (this.releaseInfo.path === '/service/desk/release') {
        result = isServiceManage()
      }
      this.showReplace = result
    },
    goRelease() {
      this.$router.push({
        path: this.releaseInfo.path,
        query: {},
      })
    },
    search() {
      this.$emit('search')
    },
  },
}
</script>

<style scoped lang="less">
@import '~@/styles/variables.less';
.search {
  display: flex;
  align-items: center;
  margin: 40px 0 20px;
  .context {
    position: relative;
    width: 100%;
  }
  .searchInput {
    background-color: #fff;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    height: 48px;
    line-height: 48px;
    outline: 0;
    padding: 0 30px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
  }
  .inputPrefix {
    position: absolute;
    top: 0;
    left: 5px;
    transition: all 0.3s;
    height: 100%;
    color: #bdbdbd;
    text-align: center;
  }
  .searchBuuton {
    width: 118px;
    height: 48px;
    margin-left: 10px;
    background: #00a4ff;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    line-height: 48px;
    text-align: center;
    cursor: pointer;
  }

  .release {
    margin-left: 24px;
    font-size: 14px;
    font-weight: 400;
    color: @uni-text-color-select;
    white-space: nowrap;
    cursor: pointer;
  }
}
</style>
