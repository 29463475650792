var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"commonSelection"},[_c('div',{staticClass:"selected"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("be315ca"))+"：")]),_c('div',{staticClass:"content"},[(_vm.showClear)?_c('div',{staticClass:"clear item",on:{"click":_vm.clearSelect}},[_vm._v(" "+_vm._s(_vm.$t("df61523"))+" ")]):_vm._e(),_vm._l((_vm.selectObj),function(content,key){return _c('div',{key:key,staticClass:"item"},[_vm._v(" "+_vm._s(content.name)+" "),_c('div',{staticClass:"closeSel",on:{"click":function($event){return _vm.clearItem(content, key)}}})])})],2)]),_c('div',{staticClass:"conditionSelection"},_vm._l((_vm.options),function(item,index){return (!item.colse)?_c('div',{key:index,staticClass:"condition"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t(item.name))+"：")]),_c('div',{staticClass:"content"},_vm._l((item.list),function(content,contentIndex){return _c('div',{key:content.id,staticClass:"item",class:{
            active: _vm.selectObj[item.key]
              ? _vm.selectObj[item.key].name === content.name
              : false,
          },on:{"click":function($event){return _vm.selectItem(contentIndex, content, item)}}},[(_vm.showPopover && content.child)?_c('el-popover',{attrs:{"placement":"bottom-start","width":"1050","trigger":"hover","popper-class":"popoverContainer"}},[_c('div',{staticClass:"childContainer"},_vm._l((content.child.list),function(childItme,childIndex){return _c('div',{key:childItme.id + index,staticClass:"childItem",class:{
                  active: _vm.selectObj[content.child.key]
                    ? _vm.selectObj[content.child.key].name === childItme.name
                    : false,
                },on:{"click":function($event){return _vm.selectItem(
                    childIndex,
                    childItme,
                    content.child,
                    content,
                    item
                  )}}},[_vm._v(" "+_vm._s(childItme.name)+" ")])}),0),_c('span',{attrs:{"slot":"reference"},slot:"reference"},[_vm._v(_vm._s(content.name))])]):_c('span',[_vm._v(_vm._s(content.name))])],1)}),0)]):_vm._e()}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }