<template>
  <div class="office" @click="goOffice">
    <el-popover
      placement="top-start"
      width="200"
      trigger="hover"
      :content="data.title"
    >
      <div slot="reference" class="title">{{ data.title | textFilter }}</div>
    </el-popover>

    <div v-for="(item, index) of lableData" :key="item.id" class="uiText">
      <div class="lable">{{ item.lable }}：</div>
      <div
        :class="classObj(item, index)"
        :style="styleObj(data[item.key], index)"
      >
        {{ handleData(item.key, data) | textFilter | urgentStatus }}
      </div>
    </div>
    <div class="details" @click="goDetalis">{{ detalisText }}</div>
  </div>
</template>

<script>
export default {
  name: 'Office',
  props: {
    data: {
      type: Object,
      required: true,
    },
    lableData: {
      type: Array,
      required: true,
    },
    detalisText: {
      type: String,
      default: '查看详情',
    },
    path: {
      type: String,
      default: '/service/demand/details',
    },
  },
  data() {
    return {}
  },
  methods: {
    handleData(key, data) {
      if (typeof key === 'string') {
        return data[key]
      } else if (Array.isArray(key)) {
        return data[key[0]] + (data[key[1]] ? '-' + data[key[1]] : '')
      }
    },
    goOffice() {
      // this.$router.push({})
    },
    classObj(item, index) {
      // this.$router.push({})
      // if (index === 5) {
      //   return {
      //     text: true,
      //   }
      // } else
      if (item.key === 'businessType') {
        return {
          text: true,
        }
      } else if (item.key === 'content') {
        return {
          'uni-ellipsis': true,
        }
      }
    },
    styleObj(key, index) {
      const obj = {
        EMERGENCY: '#F5222D',
        NORMAL: '#51C518',
      }
      if (obj[key]) {
        return {
          color: obj[key],
        }
      }
    },
    goDetalis() {
      this.$router.push({
        path: this.path,
        query: {
          id: this.data.id,
        },
      })
    },
  },
}
</script>

<style scoped lang="less">
@import '~@/styles/variables.less';
@import '~@/styles/common.less';
.office {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 285px;
  height: 368px;
  margin-bottom: 20px;
  padding: 40px 20px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(2, 24, 36, 0.04);
  border: 1px solid #e5e8ed;
  font-size: 14px;
  font-weight: 400;
  color: #666666;

  .title {
    padding-bottom: 8px;
    font-size: 20px;
    font-weight: 500;
    color: #333333;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .uiText {
    display: flex;
    width: 245px;
    margin-top: 12px;
    .lable {
      white-space: nowrap;
    }
    .text {
      position: relative;
      top: -3px;
      letter-spacing: 0;
      overflow: hidden;
      display: -webkit-box;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      line-height: 20px;
    }
  }

  .details {
    position: absolute;
    bottom: 40px;
    box-sizing: border-box;
    width: 245px;
    height: 36px;
    margin-top: 20px;
    border-radius: 4px;
    border: 1px solid @uni-text-color-select;
    color: @uni-text-color-select;
    line-height: 36px;
    text-align: center;
    cursor: pointer;
    &:hover {
      border: none;
      color: #ffffff;
      background: linear-gradient(180deg, #05d0fe 0%, #2c6fe8 100%);
    }
  }
}
</style>
